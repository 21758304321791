html {
    scroll-behavior: smooth;
}
body {
    font-family: 'Montserrat', sans-serif;
    color: #323333;
}

.navbar-brand span {
    font-weight: 600;
    position: relative;
    top: 0px;
    padding-left: 5px;
    color: #006E5C;
}


/*used to hide Zoho Help icon*/
#zsiq_float {display: none;}


.navbar-light .navbar-nav .nav-link {
    color: rgb(0 0 0);
    padding: 5px 6px;
    font-size: 16px;
    margin: 0 8px;
    transition: 0.3s ease all;
    font-weight: 500;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: #3ecadc;
    transition: 0.3s ease all;
}
.hvr-underline-from-center:before {
    background: #3ecadc;
    height: 2px;
}

.navbar-light .navbar-nav .nav-link.btn {
    font-size: 16px;
    padding: 7px 17px;
    margin: 0 3px;
}
.navbar-light .navbar-nav .nav-link.btn.btn-primary {
    color: white;
}
.btn-primary {
    color: #fff;
    background-color: #3ecadc;
    border-color: #3ecadc;
    padding: 10px 19px;
    font-weight: 500;
}
.btn-outline-primary {
    color: #3ecadc;
    border-color: #3ecadc;
}
.btn-primary:hover {
    background: #000 !important;
    border-color: #000 !important;
}
.btn-outline-primary:hover {
        background: #000 !important;
    border-color: #000 !important;
    color: white !important;
}


#__bs_notify__ {
    display: none !important;
}


section.main {
    padding: 60px 0 110px 0;
}
.main_title {
    margin-bottom: 40px;
}
.main_title h1 {
    text-align: center;
    font-weight: 600;
}
.main_title h1 span {
    border-bottom: 11px solid #3ecadc69;
    display: inline-block;
    line-height: 17px;
}
.main .container {
    background: url(./images/grid.png);
    background-size: 575px;
    background-repeat: no-repeat;
    background-position: left bottom;
}
.main_content img {
    width: 100%;
}

.main_content_l {
    max-width: 400px;
}
.main_content_l h5 {
    font-weight: 500;
    background: #3ecadc;
    margin: 0;
    display: inline-block;
    border-radius: 5px;
    padding: 6px 11px;
    font-size: 16px;
    color: white;
}
.main_content_l h2 {
    margin-top: 10px;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 30px;
}
.main_content_l p {
    font-size: 16px;
    font-weight: 500;
}

section.section2 {
    background: url(./images/11062b_dbb18569e65f4149abb7bb60eb0656e0_mv2_d_3840_2400_s_4_2.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 40px;
}


.s2_content_l {
    text-align: center;
}
.s2_content_l img {
    width: 100%;
    max-width: 290px;
    margin-top: -60px;
}

.s2_content h2 {
    color: white;
    font-weight: 600;
    margin-bottom: 30px;
}
.s2_content ul {
    margin-bottom: 40px;
}
.s2_content ul li {
    color: white;
    font-size: 18px;
    margin: 11px 0px;
}
.s2_content a {

}

.section3 {
    padding: 60px 0;
}

.s3_content h2 {
    font-weight: 600;
    margin-bottom: 23px;
    font-size: 43px;
    color: #a0a094;
}
.s3_content h2 span {
    color: #4a496d;
}
.s3_content p {
    font-size: 17px;
    font-weight: 400;
}

.s3_content {
    max-width: 400px;
}

.s3_content_r {
    margin-left: -180px;
}
.s3_content_r img {
    width: 100%;
}

footer {
    background: #323333;
    padding: 15px 0;
}

.ftr_content img {
    width: 30px;
}
.ftr_content span {
    color: white;
    margin-left: 4px;
    position: relative;
    top: 2px;
}
.ftr_content a {
    color: white;
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
}

@media screen and (max-width: 992px) {
    .main_content_l {
    margin-bottom: 29px;
    text-align: center;
}

.main_title h1 {
    font-size: 36px;
}

section.section2 {
    padding-top: 45px;
}
section.main {
    padding: 60px 0;
}
.s2_content_l img {
    margin-top: 20px;
}
.s3_content_r {
    margin: 0;
    margin-top: 30px;
}
.navbar-light .navbar-nav .nav-link {
    padding: 9px 6px;
}
ul.navbar-nav.mx-auto {
    padding: 15px 8px;
    background: #f8f9fa;
}
.navbar-light .navbar-nav .nav-link.btn {
    margin: 5px 0;
    display: inline-block;
}

.main_title h1 {
    font-size: 31px;
}
.main_content_l {
    max-width: 100%;
}

}
nav.navbar {
    box-shadow: 0 0 9px rgb(0 0 0 / 9%);
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
    .s3_content_r {
        margin-left: 0;
    }
}


.navbar {
        position: sticky;
    top: 0;
    z-index: 999;
}

